import React from 'react';
import styled from 'styled-components';
import StyledLink from '@makeship/core/lib/components/Link';
import ProductInfographic from '../ProductInfographic';
import { P2 } from '../../../Typography';
import { renderSupportLink } from '../commonFunctions';
import { getProductFromContext } from '../../../../context/product';
import { ProductTag } from '../../../../types/common';
import { hasProductTag } from '../../../../utils/product';
import config from '../../../../../config.json';

const { routes } = config;

const HowItWorksWrapper = styled.div``;

const SubtitleBody = styled(P2)`
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.neutral4};
`;

const SubtitleText = styled(P2)`
  color: ${({ theme }) => theme.colors.neutral4};
`;

const MoreInfoP2 = styled(P2)`
  margin-top: 12px;
`;

const HowItWorksToggle: React.FC = () => {
  const product = getProductFromContext();
  const isEvergreen = hasProductTag(product.tags, ProductTag.Evergreen);

  return isEvergreen ? (
    <>
      <HowItWorksWrapper>
        <SubtitleText>Shipping</SubtitleText>
        <P2>Each order will be shipped 2-4 days after purchase.</P2>

        <SubtitleBody>Delivery</SubtitleBody>
        <P2>
          Once shipped, estimated delivery is 7-15 business days. *Delivery to some areas may take 10-30 business days.
        </P2>

        <SubtitleBody>Returns and Refunds</SubtitleBody>
        <P2>
          All sales are final and non-refundable. For damaged products, please visit{' '}
          <StyledLink.Primary target="_blank" href={routes.helpCenter}>
            Makeship Help Center.
          </StyledLink.Primary>
        </P2>
      </HowItWorksWrapper>
    </>
  ) : (
    <HowItWorksWrapper>
      <ProductInfographic />
      <SubtitleBody>Returns and Refunds</SubtitleBody>
      <P2>
        All sales are final and non-refundable. If a Campaign does not achieve its funding goal, all orders will be
        refunded within 4-7 business days.
      </P2>
      <MoreInfoP2>Want more information? {renderSupportLink(product)}</MoreInfoP2>
    </HowItWorksWrapper>
  );
};

export default HowItWorksToggle;
